import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/CssBaseline/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ui/accessibility/WidgetLibras.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MessageSnackbar"] */ "/app/src/components/ui/snackbar/MessageSnackbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SWRConfigProvider"] */ "/app/src/contexts/SWRConfigContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["baseTheme"] */ "/app/src/styles/theme.ts");
