"use client"

import VLibras from "vlibras-nextjs"

export default function WidgetLibras() {
  return (
    <div className="WidgetLibras">
      <VLibras forceOnload />
    </div>
  )
}
