"use client"

import { useMessageSnackbarStore } from "@/stores/ui/useMessageSnackbar"
import Alert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"

// Para exibir mensagens de erros, faça da seguinte forma (não precisa importar este componente):
// const { successMessage, errorMessage } = useMessageSnackbarStore()
// successMessage("Vaga criada com sucesso!")
// errorMessage("Ocorreu um erro ao cadastrar uma nova vaga")
export function MessageSnackbar() {
  const { isMessageSnackbarOpen, closeMessageSnackbar, message, severity } =
    useMessageSnackbarStore()

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return
    }

    closeMessageSnackbar()
  }

  return (
    <Snackbar
      open={isMessageSnackbarOpen}
      autoHideDuration={6000}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      sx={{
        maxWidth: "90%",
        whiteSpace: "pre-line",
        borderRadius: 0,
      }}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%", height: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
