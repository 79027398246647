"use client"

import { lato, roboto } from "@/styles/fonts"
import { ptBR as corePtBR } from "@mui/material/locale"
import { ThemeOptions, createTheme } from "@mui/material/styles"
import { deepmerge } from "@mui/utils"
import { ptBR as datagridPtBR } from "@mui/x-data-grid/locales"
import { ptBR as datePickersPtBR } from "@mui/x-date-pickers/locales"

declare module "@mui/material/styles" {
  interface Palette {
    blueMsGov: Palette["primary"]
    greenMsGov: Palette["primary"]
    placeholder: Palette["primary"]
  }

  interface PaletteOptions {
    blueMsGov: PaletteOptions["primary"]
    greenMsGov: PaletteOptions["primary"]
    placeholder: PaletteOptions["primary"]
  }

  interface Theme {
    appHeader: {
      height: number
    }
    navigationDrawer: {
      openedWidth: number
      closedWidth: number
    }
    mobileNavigationDrawer: {
      openedHeight: number
    }
  }

  interface ThemeOptions {
    appHeader?: {
      height?: number
    }
    navigationDrawer?: {
      openedWidth?: number
      closedWidth?: number
    }
    mobileNavigationDrawer?: {
      openedHeight?: number
    }
  }
}

const baseThemeOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0.25rem",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
      ::-webkit-scrollbar { 
         width: 7px;
         } 
      ::-webkit-scrollbar-track {
         background: transparent; 
         } 
      ::-webkit-scrollbar-thumb {
         background-color: #C6D3E8;
         border-radius: 4px;
         transition: background-color 0.3s, width 0.3s, height 0.3s;
         } 
      ::-webkit-scrollbar-thumb:hover {
         background-color: #a0b8d8;
         width: 10px; height: 10px;
         } 
      ::-webkit-scrollbar-thumb:active {
         background-color: #809ac0;
         width: 10px;
         height: 10px;
         }
      `,
    },
  },
  palette: {
    mode: "light",
    background: {
      default: "#f0f5fa",
      paper: "#ffffff",
    },
    text: {
      primary: "rgba(0,0,0,0.75)",
      secondary: "rgba(0,0,0,0.6)",
    },
    error: {
      main: "#e57373",
    },
    warning: {
      main: "#fb9949",
    },
    info: {
      main: "#2d78a7",
      light: "#03a9f4",
    },
    primary: {
      main: "#2555ab",
      light: "#5077bb",
      dark: "#193b77",
    },
    blueMsGov: {
      main: "#2555ab",
      light: "#5077bb",
      dark: "#193b77",
    },
    greenMsGov: {
      main: "#009640",
      light: "#9dcc90",
      dark: "#00692c",
    },
    placeholder: {
      main: "#49454f",
      light: "#6d6a72",
      dark: "#333037",
    },
  },
  typography: {
    fontFamily: roboto.style.fontFamily,
    h1: {
      fontFamily: lato.style.fontFamily,
    },
    h2: {
      fontFamily: lato.style.fontFamily,
    },
    h3: {
      fontFamily: lato.style.fontFamily,
    },
    h4: {
      fontFamily: lato.style.fontFamily,
    },
    h5: {
      fontFamily: lato.style.fontFamily,
      fontWeight: 700,
    },
    h6: {
      fontFamily: lato.style.fontFamily,
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 16,
  },
  appHeader: {
    height: 100,
  },
  navigationDrawer: {
    openedWidth: 250,
    closedWidth: 90,
  },
  mobileNavigationDrawer: {
    openedHeight: 325,
  },
}

const baseTheme = createTheme(baseThemeOptions, corePtBR, datePickersPtBR, datagridPtBR)

const applicantsTheme = createTheme(
  deepmerge(baseThemeOptions, {
    palette: {
      primary: {
        main: "#2555ab",
        light: "#5077bb",
        dark: "#193b77",
      },
      secondary: {
        main: "#009640",
        light: "#9dcc90",
        dark: "#00692c",
      },
      background: {
        default: "#f0f5fa",
      },
    },
  }),
  corePtBR,
  datePickersPtBR,
  datagridPtBR,
)

const employerTheme = createTheme(
  deepmerge(baseThemeOptions, {
    palette: {
      primary: {
        main: "#009640",
        light: "#9dcc90",
        dark: "#00692c",
      },
      secondary: {
        main: "#2555ab",
        light: "#5077bb",
        dark: "#193b77",
      },
      background: {
        default: "#F2FAF0",
      },
    },
  }),
  corePtBR,
  datePickersPtBR,
  datagridPtBR,
)

const FUNTRABTheme = createTheme(
  deepmerge(baseThemeOptions, {
    palette: {
      primary: {
        main: "#009640",
        light: "#EFFAD8",
        dark: "#193b77",
      },
      secondary: {
        main: "#2555ab",
        light: "#5077bb",
        dark: "#193b77",
      },
      background: {
        default: "#EFEFEF",
      },
    },
  }),
  corePtBR,
  datePickersPtBR,
  datagridPtBR,
)

const SEMADESCtheme = createTheme(
  deepmerge(baseThemeOptions, {
    palette: {
      primary: {
        main: "#2555ab",
        light: "#5077bb",
        dark: "#193b77",
      },
      secondary: {
        main: "#8BC34A",
        light: "#C8E6C9",
        dark: "#558B2F",
      },
      background: {
        default: "#E7EDF5",
      },
    },
  }),
  corePtBR,
  datePickersPtBR,
)

export { baseTheme, applicantsTheme, employerTheme, FUNTRABTheme, SEMADESCtheme }
