"use client"

import { fetcher } from "@/libs/api"
import { SWRConfig } from "swr"

// Reference: https://github.com/vercel/swr/issues/2694

export function SWRConfigProvider({ children }: { children: React.ReactNode }) {
  return (
    <SWRConfig
      value={{
        fetcher,
        keepPreviousData: true,
        dedupingInterval: 60000,
        revalidateOnFocus: false,
        revalidateOnReconnect: true,
        refreshInterval: 0,
      }}
    >
      {children}
    </SWRConfig>
  )
}
