import Cookies from "js-cookie"

const TOKEN_KEY = "jwt_token"

export const setJwtToken = (token: string) => {
  Cookies.set(TOKEN_KEY, token)
}

export const getJwtToken = (): string | undefined => {
  return Cookies.get(TOKEN_KEY)
}

export const removeJwtToken = () => {
  Cookies.remove(TOKEN_KEY)
}
